/**
 * Generate the <Link rel="preload" as="image" ... /> for the hero images. (mobile & desktop)
 */
import type { MetaDescriptor } from '@remix-run/node';
import type { ImageConfigProps } from '@modules/images';
import { imageConfig } from '@modules/images';
import type { ImageLoader } from '@archipro-design/aria';

export const getPreloadImageLink = (
    imageConfigProps?: ImageConfigProps,
    imageDesktop?: string,
    imageMobile?: string,
    imageSizes?: string,
    customLoader?: ImageLoader
): MetaDescriptor[] => {
    const defaultLinksAttr = {
        rel: 'preload',
        as: 'image',
        tagName: 'link',
    };
    const dynamicImageLinks: MetaDescriptor[] = [];
    const loader = customLoader ?? imageConfig(imageConfigProps).loader;

    if (imageDesktop) {
        dynamicImageLinks.push({
            ...defaultLinksAttr,
            href: loader({
                src: imageDesktop,
                width: 3840,
            }),
            media: '(min-width: 1024px)',
            imageSrcSet: generateImageSrcSet(
                imageConfigProps,
                loader,
                imageDesktop
            ),
            imageSizes: imageSizes || '100vw',
        });
    }

    if (imageMobile) {
        dynamicImageLinks.push({
            ...defaultLinksAttr,
            href: loader({
                src: imageMobile,
                width: 3840,
            }),
            media: '(max-width: 768px)',
            imageSrcSet: generateImageSrcSet(
                imageConfigProps,
                loader,
                imageMobile
            ),
            imageSizes: imageSizes || '100vw',
        });
    }

    return dynamicImageLinks;
};

// No-export, only for usage in getPreloadImageLink
const generateImageSrcSet = (
    imageConfigProps: ImageConfigProps | undefined,
    loader: ImageLoader,
    src: string
): string => {
    const deviceSizes = imageConfig(imageConfigProps).deviceSizes;

    return deviceSizes
        .map((w) => {
            return `${loader({
                src: src,
                width: w,
            })} ${w}w`;
        })
        .join(',');
};
